import { breakpointVal } from '@graphcommerce/next-ui'
import { Box, Typography } from '@mui/material'

type CountdownUnitProps = {
  value: number
  id: string
}

export function CountdownUnit(props: CountdownUnitProps) {
  const { value, id } = props
  const formattedValue = value.toString().padStart(2, '0')
  const digits = formattedValue.split('')

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: { xs: 1, sm: theme.spacings.xxs },
          justifyContent: 'center',
        })}
      >
        {digits.map((digit, index) => {
          const uniqueKeys = `${id}${digit}${index}`

          return (
            <Box
              key={uniqueKeys}
              sx={(theme) => ({
                mt: theme.spacings.xxs,
                pt: theme.spacings.xxs,
                pb: theme.spacings.xs,
                px: theme.spacings.sm,
                ...breakpointVal(
                  'borderRadius',
                  theme.shape.borderRadius * 4,
                  theme.shape.borderRadius * 7,
                  theme.breakpoints.values,
                ),
                backgroundColor: '#024765',
                textAlign: 'center',
              })}
            >
              <Typography
                component='h2'
                variant='h1'
                sx={(theme) => ({
                  '&.MuiTypography-root': {
                    ...breakpointVal('fontSize', 28, 130, theme.breakpoints.values),
                  },
                  color: 'white',
                })}
              >
                {digit}
              </Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
