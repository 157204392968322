import { breakpointVal } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { SxProps, Theme, Container, Typography, Box, styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { CountdownUnit } from './CountdownUnit'
import { RowCountdownFragment } from './RowCountdown.gql'

type RowCountdownProps = RowCountdownFragment & {
  sx?: SxProps<Theme>
}

export function RowCountdown(props: RowCountdownProps) {
  const { sx = [], countdownDate, id } = props

  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  })
  const [showColons, setShowColons] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const targetDate = new Date(countdownDate as string).getTime()

    const updateCountdown = () => {
      const now = new Date().getTime()
      const difference = targetDate - now

      if (difference <= 0) {
        setTimeRemaining({ days: 0, hours: 0, minutes: 0 })
        return
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24))
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))

      setTimeRemaining({ days, hours, minutes })
      setIsLoading(true)
    }

    const interval = setInterval(updateCountdown, 1000)
    const colonInterval = setInterval(() => {
      setShowColons((prev) => !prev)
    }, 1000)

    updateCountdown()

    return () => {
      clearInterval(interval)
      clearInterval(colonInterval)
    }
  }, [countdownDate])

  const ColonTypography = styled(Typography)(({ theme }) => ({
    ...breakpointVal('fontSize', 24, 130, theme.breakpoints.values),
    color: '#1C99B7',
    visibility: showColons ? 'visible' : 'hidden',
  }))

  if (!isLoading) return null

  return (
    <Box sx={[(theme) => ({ my: theme.spacings.xxl }), ...(Array.isArray(sx) ? sx : [sx])]}>
      <Container
        sx={(theme) => ({ display: 'grid', justifyContent: 'center', gap: theme.spacings.xs })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: { xs: theme.spacings.xxs, md: theme.spacings.sm },
          })}
        >
          <CountdownUnit id={`${id}-days`} value={timeRemaining.days} />
          <ColonTypography>:</ColonTypography>
          <CountdownUnit id={`${id}-hours`} value={timeRemaining.hours} />
          <ColonTypography>:</ColonTypography>
          <CountdownUnit id={`${id}-minutes`} value={timeRemaining.minutes} />
        </Box>

        <Box
          sx={(theme) => ({
            display: 'grid',
            gap: theme.spacings.xxl,
            gridTemplateColumns: 'repeat(3, 1fr)',
          })}
        >
          <Typography variant='body1' sx={{ textAlign: 'center', textTransform: 'uppercase' }}>
            <Trans id='Days' />
          </Typography>
          <Typography variant='body1' sx={{ textAlign: 'center', textTransform: 'uppercase' }}>
            <Trans id='Hours' />
          </Typography>
          <Typography variant='body1' sx={{ textAlign: 'center', textTransform: 'uppercase' }}>
            <Trans id='Minutes' />
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}
