import { LazyHydrate, RenderType, TypeRenderer } from '@graphcommerce/next-ui'
import { RowBlogContent } from '../Blog'
import { PageContentQueryFragment } from './PageContentQueryFragment.gql'
import { RowBannerSlider } from './RowBannerSlider/RowBannerSlider'
import { RowButton } from './RowButton/RowButton'
import { RowButtonLinkList } from './RowButtonLinkList/RowButtonLinkList'
import { RowCard } from './RowCard/RowCard'
import { RowCaseReference } from './RowCaseReference/RowCaseReference'
import { RowColumnOne } from './RowColumnOne/RowColumnOne'
import { RowColumnThree } from './RowColumnThree/RowColumnThree'
import { RowColumnTwo } from './RowColumnTwo/RowColumnTwo'
import { RowContentLinks } from './RowContentLinks/RowContentLinks'
import { RowCountdown } from './RowCountdown/RowCountdown'
import { RowEvent } from './RowEvent/RowEvent'
import { RowEventCalendar } from './RowEventCalendar/RowEventCalendar'
import { RowFormsApp } from './RowFormsApp/RowFormsApp'
import { RowHeroBanner } from './RowHeroBanner/RowHeroBanner'
import { RowLinks } from './RowLinks/RowLinks'
import { RowProduct } from './RowProduct/RowProduct'
import { RowQuote } from './RowQuote/RowQuote'
import { RowRendererFragment } from './RowRenderer.gql'
import { RowServiceOptions } from './RowServiceOptions/RowServiceOptions'
import { RowSpecialBanner } from './RowSpecialBanner/RowSpecialBanner'
import { RowSwipeablePageLinks } from './RowSwipeablePageLinks/RowSwipeablePageLinks'

type ContentTypeRenderer = TypeRenderer<PageContentQueryFragment['pages'][0]['content'][0]>

const defaultRenderer: Partial<ContentTypeRenderer> = {
  RowColumnOne,
  RowColumnTwo,
  RowColumnThree,
  RowHeroBanner,
  RowSpecialBanner,
  RowQuote,
  RowBlogContent,
  RowButtonLinkList,
  RowServiceOptions,
  RowContentLinks,
  RowProduct,
  RowBannerSlider,
  RowCaseReference,
  RowFormsApp,
  RowSwipeablePageLinks,
  RowLinks,
  RowEvent,
  RowButton,
  RowEventCalendar,
  RowCard,
  RowCountdown,
}

export type PageProps = RowRendererFragment & {
  renderer?: Partial<ContentTypeRenderer>
  loadingEager?: number
}

export function RowRenderer(props: PageProps) {
  const { content, renderer, loadingEager = 2 } = props
  const mergedRenderer = { ...defaultRenderer, ...renderer } as ContentTypeRenderer
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {content?.map((item, index) => (
        <LazyHydrate key={item.id} hydrated={index < loadingEager ? true : undefined} id={item.id}>
          <RenderType renderer={mergedRenderer} {...item} />
        </LazyHydrate>
      ))}
    </>
  )
}
